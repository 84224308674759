import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';

const PatientPortalMacra = () => {
  return (
    <Layout>
      <Seo
        title="MACRA, MIPS and Meaningful Use Resources"
        description="Learn about MACRA, MIPS and Meaningful Use, and how patient engagement technology can help meet these requirements."
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1>Patient Portals and MACRA / MIPS / Meaningful Use</h1>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <h2>Patient Portals and MACRA / MIPS / Meaningful Use</h2>
              <h3>Meaningful Use</h3>
              <p>
                The Centers for Medicare & Medicaid Services (CMS) established
                the EHR Incentive Programs (also known as meaningful use) in
                2011. The EHR Incentive Program encourages Eligible
                Professionals (EPs), Critical Access Hospitals (CAHs), and
                eligible hospitals to execute, manage, and prove meaningful use
                of Certified Electronic Health Record Technology (CEHRT).
              </p>
              <p>
                Participants are scored on four objectives¹ (Electronic
                Prescribing, Health Information Exchange, Provider to Patient
                Exchange, and Public Health and Clinical Data Exchange).
                Depending on their score, participants can receive incentive
                payments/penalties.
              </p>
              <p>There have been three stages to meaningful use:</p>
              <ul className="h-clear-list h-bullet-list">
                <li>Stage 1 (start date 2011): Data capture and sharing</li>
                <li>Stage 2 (start date 2014): Advanced clinical processes</li>
                <li>Stage 3 (start date 2017): Improved outcomes</li>
              </ul>
              <p>
                In 2018, Meaningful Use (MU) became known as Promoting
                Interoperability Programs². Participants can attest to the
                Medicare Promoting Interoperability Programs or Medicaid
                Promoting Interoperability Programs.
              </p>
              <h3>MACRA</h3>
              <p>
                On April 16, 2015 The Medicare Access and CHIP Reauthorization
                Act of 2015 (MACRA) was signed into law. Under MACRA, providers
                caring for Medicare beneficiaries will be paid based on the
                quality of the care they provide.
              </p>
              <h3>MIPS</h3>
              <p>
                Merit Based Incentive Payments System (MIPS) is a payment track
                created under MACRA. It aims to link payments to the quality of
                care provided, improve care processes and health outcomes,
                increase the use of healthcare information, and reduce the cost
                of care.
              </p>
              <h2>
                What is the difference between Meaningful Use Stage 3 (MU3),
                MACRA, and MIPS?
              </h2>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th scope="col">Meaningful Use - Stage 3 (MU3)</th>
                    <th scope="col">MACRA</th>
                    <th scope="col">MIPS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Function</strong>
                    </td>
                    <td>
                      Healthcare organizations wanting to receive incentive
                      payments through CMS must demonstrate meaningful use of an
                      electronic health record to improve health outcomes. Also,
                      this rule modified MU2 to ease reporting requirements and
                      align with other CMS programs.
                    </td>
                    <td>
                      Provides new ways to pay physicians for caring for
                      Medicare beneficiaries. Under MACRA, participating
                      providers will be paid based on the quality and
                      effectiveness of the care they provide.
                    </td>
                    <td>
                      <p>
                        Provides new ways to pay physicians for caring for
                        Medicare beneficiaries. Under MACRA, participating
                        providers will be paid based on the quality and
                        effectiveness of the care they provide.
                      </p>
                      <ol>
                        <li>Quality</li>
                        <li>Improvement Activities</li>
                        <li>Cost</li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Initiation Date</strong>
                    </td>
                    <td>2015</td>
                    <td>2015</td>
                    <td>2017</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Governing Authority</strong>
                    </td>
                    <td>CMS & OMS</td>
                    <td>CMS</td>
                    <td>CMS</td>
                  </tr>
                </tbody>
              </Table>
              <h2>
                What was the timeline of Meaningful Use and MACRA/MIPS programs?
              </h2>
              <h3>2009</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  The American Reinvestment and Recovery Act (ARRA) was enacted.
                </li>
                <li>
                  The accompanying Health Information Technology for Economic
                  and Clinical Health (HITECH) Act was also enacted.
                </li>
              </ul>
              <h3>2011</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  EHR Incentive Programs (Also known as Meaningful Use) Start
                  January 2011
                </li>
                <li>Meaningful Use Stage 1 enacted</li>
                <li>
                  January 2011 Start of EHR Incentive Programs registration
                </li>
                <li>
                  April 2011 Start of the Medicare EHR Incentive Program
                  attestation
                </li>
                <li>May 2011 Start of EHR Incentive Payments</li>
                <li>
                  November 30, 2011, Last day for eligible hospitals and CAHs to
                  register and attest to receive an Incentive Payment for 2011
                </li>
              </ul>
              <h3>2012</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  FEBRUARY 29, 2012 Last day for EPs to register and attest to
                  receive an Incentive Payment for 2011.
                </li>
              </ul>
              <h3>2014</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>Meaningful Use Stage 2 enacted.</li>
                <li>
                  Last year to initiate participation in the Medicare EHR
                  Incentive Program.
                </li>
              </ul>
              <h3>2015</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  The Medicare Access and CHIP Reauthorization Act of 2015
                  (MACRA) was signed into law on April 16, 2015.
                </li>
                <li>Modified Stage 2</li>
                <li>
                  In October 2015, CMS released the Modifications to Meaningful
                  Use in 2015 through 2017 final rule³, which modified Stage 2
                  requirements to streamline reporting requirements on measures.
                </li>
                <li>
                  Medicare payment adjustments begin for EPs and eligible
                  hospitals that are not meaningful users of EHR technology.
                </li>
              </ul>
              <h3>2016</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>Last year to receive a Medicare EHR Incentive Payment.</li>
                <li>
                  Last year to initiate participation⁴ in the Medicaid EHR
                  Incentive Program.
                </li>
              </ul>
              <h3>2017</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>The first performance period for MACRA.</li>
                <li>Meaningful Use Stage 3 enacted.</li>
                <li>
                  QPP (Quality Payment Program)⁵ started on January 1, 2017.
                </li>
              </ul>
              <h3>2018</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  EHR Incentive Programs changed its name to Promoting
                  Interoperability Programs⁶.
                </li>
                <li>
                  Eligible Practitioners and Eligible Clinicians who previously
                  participated in Medicare Promoting Interoperability Programs
                  are now required to report for QPP.
                </li>
              </ul>
              <h3>2020</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  All EPs must attest to Stage 3 MU objectives Eligible
                  Professionals (EPs): December 1, 2020, to February 28, 2021.
                </li>
              </ul>
              <h3>2021</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Promoting Interoperability: Medicaid⁷ will end on September
                  30, 2021. No payments⁸ will be made to any provider after
                  2021. There is a 90-day reporting period for both EHR and eCQM
                  for all EPs⁹ to meet the incentive payment deadline of
                  December 31, 2021.
                </li>
                <li>
                  Promoting Interoperability: Medicare¹⁰ incentive program will
                  end on September 30, 2021, and any outstanding incentive
                  payments will stop. Penalty payment adjustment for
                  non-attestation will stay in place.
                </li>
                <li>
                  March 1, 2021 Deadline to submit 2020 data for the Medicare
                  Promoting Interoperability Program.
                </li>
              </ul>
              <h3>2022</h3>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Participants of the Medicare Promoting Interoperability
                  Program¹¹ (eligible hospitals and CAHs) are required to report
                  for any continuous 90-day period attesting to CMS.{' '}
                </li>
              </ul>
              <h2>What is the financial impact of compliance with MU?</h2>
              <p>
                Under the Medicaid Electronic Health Record Incentive Payments
                for Eligible Professionals¹², to receive the maximum incentive
                payments, participants must meet the 30 percent patient volume
                requirement. Participants who achieved between 20-30 percent
                patient volume will receive two-thirds of the total incentive
                payment.
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th scope="col">Year</th>
                    <th colSpan="6">Medicaid EPs Who Adopted In</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>&nbsp;</td>
                    <td>2011</td>
                    <td>2012</td>
                    <td>2013</td>
                    <td>2014</td>
                    <td>2015</td>
                    <td>2016</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2011</strong>
                    </td>
                    <td>$21,250</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2012</strong>
                    </td>
                    <td>$8,500</td>
                    <td>$21,250</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2013</strong>
                    </td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$21,250</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2014</strong>
                    </td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$21,250</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2015</strong>
                    </td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td> $21,250</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2016</strong>
                    </td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$21,250</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2017</strong>
                    </td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2018</strong>
                    </td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2019</strong>
                    </td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2020</strong>
                    </td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td>$8,500</td>
                    <td>$8,500</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2021</strong>
                    </td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td className="bg-light-secondary">&nbsp;</td>
                    <td>$8,500</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>$63,750</td>
                    <td>$63,750</td>
                    <td>$63,750</td>
                    <td>$63,750</td>
                    <td>$63,750</td>
                    <td>$63,750</td>
                  </tr>
                </tbody>
              </Table>
              <p>
                <em>
                  Note: the total for pediatricians who meet the 20 percent
                  patient volume but fall short of the 30 percent patient volume
                  is $14,167 in the first year and $5,667 in subsequent years.
                  This adds up to a maximum Medical EHR incentive payment of
                  $42,500 over a six-year period.
                </em>
              </p>
              <p>
                Under Medicare¹⁴, the EHR incentive payment amount is equal to
                75 percent* of an Eligible Professional&apos;s Medicare
                physician fee schedule allowed charges submitted not later than
                two months after the end of the calendar year. *subject to an
                annual limit.
              </p>
              <h2>What are the timeline/deadlines of MU?</h2>
              <h3>Medicaid</h3>
              <p>
                Reporting period: a 90-day period within the calendar year. The
                attestation period and deadline are dependent on state Medicaid.
              </p>
              <h3>Medicare</h3>
              <p>
                Reporting period: Minimum of any continuous 90-day period, for
                both 2020 and 2021/ 90-day period in the calendar year 2020.
                March 1, 2021, is the deadline to submit 2020 data for the
                Medicare Promoting Interoperability Program.
              </p>
              <h2>How does a patient portal impact MU attestation?</h2>
              <p>
                All participants in the Medicaid and Medicare Promoting
                Interoperability Programs are required to use 2015 Edition
                CEHRT.{' '}
                <Link
                  as="a"
                  to="https://www.bridgepatientportal.com/onc-modular-ehr-2015-certification-transparency-disclosure/"
                >
                  <strong>Bridge is a 2015 certified patient portal;</strong>
                </Link>{' '}
                therefore, healthcare organizations can use Bridge to attest to
                Medicare and Medicaid Promoting Interoperability Programs.
              </p>
              <p>
                Medicare: Eligible hospitals, CAHs, and dual-eligible hospitals
                attesting to Medicare Promoting Interoperability Program to CMS
                will be required to report on four objectives.
              </p>
              <ul className="h-clear-list h-bullet-list">
                <li>Electronic Prescribing</li>
                <li>Health Information Exchange</li>
                <li>Provider to Patient Exchange</li>
                <li>Public Health and Clinical Data Exchange</li>
              </ul>
              <p>
                Medicaid: Eligible professionals (EPs) and eligible hospitals
                are required to report on any six eCQMs¹⁷ (Electronic Clinical
                Quality Measures) for{' '}
                <strong>Medicaid Promoting Interoperability Program</strong>{' '}
                attestation, as well as report on at least one outcome measure.
              </p>

              <h3>Is MU3 still relevant/mentioned?</h3>
              <p>
                The term Meaningful Use is no longer used or relevant. EHR
                Incentive Programs (Also known as Meaningful Use) changed its
                name to Promoting Interoperability Programs in 2018, though some
                continue to refer to this as Meaningful Use Stage 3. Promoting
                Interoperability: Medicaid¹⁸ will end on September 30, 2021.
                Promoting Interoperability: Medicare¹⁹ incentive program will
                end on September 30, 2021, and any outstanding incentive
                payments will stop. Penalty payment adjustment for
                non-attestation will stay in place.
              </p>
              <h2>
                What is the financial impact of compliance with MACRA/MIPS?
              </h2>
              <p>
                Clinicians providing valuable patient care of high quality in
                compliance with MACRA/MIPS are rewarded through an increase in
                Medicare, while clinicians not performing to MACRA/MIPS
                standards will receive a reduction in Medicare payments. The
                MIPS eligible clinician&apos;s final score range is 0 to 100 and
                determines their payment adjustment.
              </p>
              <p>
                Payment adjustments are applied based on an eligible
                clinician&apos;s performance two years after the performance
                year. MIPS participants in 2020 will receive a payment
                adjustment in 2022 based on 2020 data. Payment adjustments are
                applied only to payments made for covered professional services
                provided by a MIPS eligible clinician. Eligible clinician&apos;s
                earning incentives are effectively being paid by those receiving
                penalties for substandard performance.
              </p>
              <p>
                Below we present the MIPS payment adjustments, including the
                maximum impact range. The history of payment adjustments, as
                well as predicted future payment adjustments, is included below.
              </p>
              <p>
                Performance year - The period in which eligible clinicians
                collect data for the four performance categories.
              </p>
              <p>
                Submission window - The period in which eligible clinicians
                submit their collected data from the performance year.
              </p>
              <p>
                Payment adjustment year - The year that eligible
                clinicians&apos; payment is either negatively or positively
                impacted based on their data collected in the performance year.
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th scope="col">Performance Year</th>
                    <th scope="col">Payment Adjustment Year</th>
                    <th scope="col">Performance Treshold</th>
                    <th scope="col">Maximun -% MIPS Penalty</th>
                    <th scope="col">Maximun +% MIPS base incentive</th>
                    <th scope="col">
                      Maximun +% MIPS exceptional performance bonus
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>2017</strong>
                    </td>
                    <td>2019</td>
                    <td>3</td>
                    <td>-4</td>
                    <td>+4%*X(Actual 0.29%)</td>
                    <td>+10%*Y(Actual 1.59%)</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2018</strong>
                    </td>
                    <td>2020</td>
                    <td>15</td>
                    <td>-5</td>
                    <td>+5%*X(CMS Predict 0.30%)</td>
                    <td>+10%*Y(Actual 1.75%)</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2019</strong>
                    </td>
                    <td>2021</td>
                    <td>30</td>
                    <td>-7</td>
                    <td>+7%*X(CMS Predict 1.11%)</td>
                    <td>+10%*Y(Actual 3.58%)</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2020</strong>
                    </td>
                    <td>2022</td>
                    <td>45</td>
                    <td>-9</td>
                    <td>+9%*X</td>
                    <td>+10%*Y</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2021</strong>
                    </td>
                    <td>2023</td>
                    <td>50</td>
                    <td>-9</td>
                    <td>+9%*X</td>
                    <td>+10%*Y</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2022</strong>
                    </td>
                    <td>2024</td>
                    <td>65</td>
                    <td>-9</td>
                    <td>+9%*X</td>
                    <td>+10%*Y</td>
                  </tr>
                </tbody>
              </Table>
              <h2>
                What are the timeline/deadlines of MACRA/MIPS attestation?
              </h2>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Submission Window for Performance Year 2017: January 2, 2018 -
                  April 3, 2018. Payment adjustment year: 2019
                </li>
                <li>
                  Submission Window for Performance Year 2018: January 2, 2019 -
                  April 2, 2019. Payment adjustment year: 2021
                </li>
                <li>
                  Submission Window for Performance Year 2019: January 2, 2020 -
                  April 30, 2020. Payment adjustment year: 2022
                </li>
                <li>
                  Submission Window for Performance Year 2020: January 4, 2021 -
                  March 31, 2021. Payment adjustment year: 2023
                </li>
                <li>
                  The extreme and uncontrollable circumstances application²⁰
                  deadline for PY 2020 is February 1, 2021, at 8 p.m. ET.
                </li>
                <li>
                  The 2021 MIPS performance year runs from January 1, 2021, to
                  December 31, 2021.
                </li>
                <li>
                  Submission Window for Performance Year 2021: January 3, 2022 -
                  March 31, 2022. Payment adjustment year: 2023
                </li>
                <li>
                  In 2022 CMS aims to implement MIPS Value Pathways (MVPs), a
                  new MIPS participation framework.
                </li>
                <li>Period of data collection for MIPS measures 2021.</li>
              </ul>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Quality</th>
                    <th>Improvement Activities</th>
                    <th>Promoting Interoperability</th>
                    <th>Cost</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Collect Data</strong>
                    </td>
                    <td>6 Measures</td>
                    <td>2 and 4 activities</td>
                    <td>6 required measures</td>
                    <td>
                      Clinicians don&apos;t need to collect or submit any data
                      for cost measures.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Period of data collection</strong>
                    </td>
                    <td>
                      12-month performance period (January 1 – December 31,
                      2021).
                    </td>
                    <td>The continuous 90-day period in Calendar Year</td>
                    <td>
                      Same 90(+)-day performance period in Calendar Year 2021.
                    </td>
                    <td>
                      12-month performance period (January 1 – December 31,
                      2021).
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h2>What are MIPS Value Pathways (MVPs)?</h2>
              <p>
                MIPS Value Pathways (MVPs), are a new MIPS participation
                framework. MVPs aim to away from siloed reporting of measures
                and activities towards focused sets of measures and activities
                that are more meaningful to a clinician’s scope of practice that
                is meaningful to patient care. CMS is working with stakeholders
                to create a MVP reporting criteria for the year 2022.
              </p>

              <h2>How does a patient portal impact MACRA/MIPS attestation?</h2>
              <p>
                A patient portal can help participants attest for Provide
                Patients Electronic Access to Their Health Information, a
                measure of the Promoting Interoperability performance category,
                which makes up 25% of the MIPS final score. Bridge is a 2015
                certified patient portal; therefore, healthcare organizations
                can use Bridge to promote interoperability for the Merit-Based
                Incentive Payment System (MIPS). With Bridge’s use, patients are
                provided timely access to view, download, and transmit their
                health information.
              </p>

              <h2>
                How has MIPS performance categories have changed over the years?
              </h2>
              <p>
                The table below demonstrates the change in performance
                categories weight over the years, including the change in the
                performance threshold.
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th scope="col">Performance Year</th>
                    <th scope="col">Quality Category %</th>
                    <th scope="col">Cost %</th>
                    <th scope="col">Promoting Interoperability %</th>
                    <th scope="col">Improvement activities %</th>
                    <th scope="col">Performance threshold</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>2018</strong>
                    </td>
                    <td>50</td>
                    <td>10</td>
                    <td>25</td>
                    <td>15</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2019</strong>
                    </td>
                    <td>45</td>
                    <td>15</td>
                    <td>25</td>
                    <td>15</td>
                    <td>30</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2020</strong>
                    </td>
                    <td>45</td>
                    <td>15</td>
                    <td>25</td>
                    <td>15</td>
                    <td>45</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2021</strong>
                    </td>
                    <td>40</td>
                    <td>20</td>
                    <td>25</td>
                    <td>15</td>
                    <td>60</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>2022</strong>
                    </td>
                    <td>30</td>
                    <td>30</td>
                    <td>25</td>
                    <td>15</td>
                    <td>65</td>
                  </tr>
                </tbody>
              </Table>
              <h2>
                Is a 2015 Edition CEHRT Required for Promoting Interoperability
                Program?
              </h2>
              <p>
                Up until 2015, all eligible providers were required to report
                using 2014 Edition CEHRT. Providers could continue using a 2014
                Edition CEHRT until 2018. In 2019, health care providers were
                required to begin using a 2015 Edition CEHRT. Participants in
                the Promoting Interoperability Programs (formerly known as
                Meaningful Use) are expected to use 2015 Edition Certified
                Electronic Health Record Technology (CEHRT).
              </p>
              <h2>What is the Cures Act?</h2>
              <p>
                In 2016, Congress passed the 21st Century Cures Act²² to drive
                the electronic access, exchange, and use of health information.
                The Office of the National Coordinator for Health Information
                Technology (ONC) Cures Act Final Rule²³ implements the
                interoperability provisions of the Cures Act to promote patient
                control over their health information. The 21st Century Cures
                Act aims to ensure that all parties (patients, caregivers, and
                healthcare providers) have appropriate access to electronic
                health information. The Act also promotes access to electronic
                health information in a secure and straightforward manner.
                Patients should be able to access their electronic medical
                records free of charge. Providers should have the freedom to
                choose easy to use, reasonably priced health IT tools to
                facilitate the best care for their patients.
              </p>
              <p>
                How can Bridge help an organization achieve MACRA/MIPS/Cures Act
                compliance? Table showing how Bridge can help organizations
                achieve compliance.
              </p>
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th scope="col">MACRA/MIPS</th>
                    <th scope="col">ONC&apos;s Cures Act Final Rule</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong>Promoting Interoperability Measures</strong>
                    </td>
                    <td>
                      <strong>Protecting Patient Privacy and Security</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Requirements This performance category promotes patient
                      engagement and electronic exchange of information using
                      Certified Electronic Health Record Technology (CEHRT).
                    </td>
                    <td>
                      The rule supports secure patient access to their
                      electronic medical record data. Patients will be able to
                      use applications they authorize to receive data from their
                      medical records. OAuth 2 is used to authorize
                      applications. Promoting the Ability to Shop for Care and
                      Manage Costs ONC&apos;s Cures Act Final Rule looks to
                      expand patient and payer choice in health care by
                      increasing data availability to support insights about
                      care quality and costs. Making Patient Data Requests Easy
                      and Inexpensive Modern technology allows clinicians and
                      hospitals to easily provide patients with access to their
                      information in a fully automated, low-cost manner.
                      Patients will be able to access their health information
                      from an app of their choice. Secure, standardized
                      Application Programming Interfaces (APIs) allow for this
                      access without special effort on the clinician&apos;s
                      part.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <p>
                Bridge Patient Portal was certified on 16 different EHR
                criteria, representing a significant range of functionality for
                an EHR-independent 3rd party patient portal solution. Bridge
                Patient Portal meets the following 2015 Edition ONC Health IT
                Certification Criteria:
              </p>
              <ul className="h-clear-list h-bullet-list">
                <li>
                  Meets 170.315 (d)(1): Authentication, Access Control,
                  Authorization
                </li>
                <li>
                  Meets 170.315 (d)(2): Auditable Events and Tamper-Resistance
                </li>
                <li>Meets 170.315 (d)(3): Audit Report(s)</li>
                <li>Meets 170.315 (d)(5): Automatic Access Time-out</li>
                <li>Meets 170.315 (d)(6): Emergency Access</li>
                <li>Meets 170.315 (d)(7): End-User Device Encryption</li>
                <li>Meets 170.315 (d)(9): Trusted Connection</li>
                <li>
                  Meets 170.315 (e)(1): View, Download, and Transmit to 3rd
                  Party
                </li>
                <li>Meets 170.315 (e)(2): Secure Messaging</li>
                <li>Meets 170.315 (g)(1): Automated Numerator Recording</li>
                <li>Meets 170.315 (g)(4): Quality Management System</li>
                <li>Meets 170.315 (g)(5): Accessibility-Centered Design</li>
                <li>Meets 170.315 (g)(6): Consolidated CDA Creation</li>
                <li>
                  Meets 170.315 (g)(7): Application Access - Patient Selection
                </li>
                <li>
                  Meets 170.315 (g)(8): Application Access - Data Category
                  Request
                </li>
                <li>
                  Meets 170.315 (g)(9): Application Access - All Data Request
                </li>
              </ul>
              <h2>How the Cures Act is related to MACRA/MIPS</h2>
              <p>
                Congress officially passed the 21st Cures Act in 2016 to
                continue the promotion of EHR adoption initiated by MACRA.
              </p>
              <p className="text-center">
                <StaticImage
                  src="../images/CuresAct-MACRA-MIPS-timeline.jpg"
                  alt=""
                />
              </p>
              <p>
                <strong>MACRA</strong> provides new ways to pay physicians for
                caring for Medicare beneficiaries. Under MACRA, participating
                providers will be paid based on the quality and effectiveness of
                the care they provide. MACRA is one of the four components of
                MIPS, which combines existing CMS quality programs (including
                meaningful use)
              </p>
              <p>
                <strong>MIPS</strong> is one of the two payment tracks created
                under MACRA. MIPS adjusts payment based on performance in four
                performance categories.
              </p>
              <p>
                <strong>Cures</strong> Patients should be able to access their
                electronic medical records at no additional cost. Providers
                should choose the IT tools to provide the best care for patients
                without high fees or technical barriers. The CURES act further
                promotes interoperability. Promoting Interoperability is 25% of
                the MIPS score. Freeing up and securing clinically relevant data
                will accelerate the move to a value-based reimbursement model.²⁴
                The bipartisan support for both MACRA and the 21st Century Cures
                Act will force the industry to move from a hospital-based model
                of care into a patient-centric model and start moving healthcare
                down the road to personalized precision medicine.
              </p>
              <hr />
              <ol className="h-reference-list">
                <li>
                  Wright, J. and Podson, D. (2019). 2019 IPPS Final Rule:
                  Medicare Promoting Interoperability Program Changes. [online]
                  Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms
                  </a>
                </li>
                <li>
                  Centers for Disease Control and Prevention (2019). Public
                  Health and Promoting Interoperability Programs. [online] CDC.
                  Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cdc.gov/ehrmeaningfuluse/introduction.html"
                  >
                    https://www.cdc.gov/ehrmeaningfuluse/introduction.html
                  </a>
                </li>
                <li>
                  Federal Register. (2015). Medicare and Medicaid Programs;
                  Electronic Health Record Incentive Program-Stage 3 and
                  Modifications to Meaningful Use in 2015 Through 2017. [online]
                  Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.federalregister.gov/documents/2015/10/16/2015-25595/medicare-and-medicaid-programs-electronic-health-record-incentive-program-stage-3-and-modifications.
"
                  >
                    https://www.federalregister.gov/documents/2015/10/16/2015-25595/medicare-and-medicaid-programs-electronic-health-record-incentive-program-stage-3-and-modifications.
                  </a>
                </li>
                <li>
                  www.healthit.gov. (n.d.). How long will the electronic health
                  record incentives opportunity last? | HealthIT.gov. [online]
                  Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.healthit.gov/faq/how-long-will-electronic-health-record-incentives-opportunity-last"
                  >
                    https://www.healthit.gov/faq/how-long-will-electronic-health-record-incentives-opportunity-last
                  </a>
                </li>
                <li>
                  qpp.cms.gov. (n.d.). QPP Overview - QPP. [online] Available
                  at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qpp.cms.gov/about/qpp-overview
"
                  >
                    https://qpp.cms.gov/about/qpp-overview
                  </a>
                </li>
                <li>
                  www.healthit.gov. (n.d.). How long will the electronic health
                  record incentives opportunity last? | HealthIT.gov. [online]
                  Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.healthit.gov/faq/how-long-will-electronic-health-record-incentives-opportunity-last"
                  >
                    https://www.healthit.gov/faq/how-long-will-electronic-health-record-incentives-opportunity-last
                  </a>
                </li>
                <li>
                  www.cms.gov. (n.d.). Promoting Interoperability Programs |
                  CMS. [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/index?redirect=/ehrincentiveprograms/#BOOKMARK2"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/index?redirect=/ehrincentiveprograms/#BOOKMARK2
                  </a>
                </li>
                <li>
                  Anon, (n.d.). CMS Promoting Interoperability in 2021: All good
                  things must come to an end? – HLN Consulting, LLC. [online]
                  Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.hln.com/cms-promoting-interoperability-in-2021-all-good-things-must-come-to-an-end/index.html#:~:text=Promoting%20Interoperability%3A%20Medicaid%20%E2%80%93%20This%20program
"
                  >
                    https://www.hln.com/cms-promoting-interoperability-in-2021-all-good-things-must-come-to-an-end/index.html#:~:text=Promoting%20Interoperability%3A%20Medicaid%20%E2%80%93%20This%20program
                  </a>
                </li>
                <li>
                  CMS Medicare and Medicaid EHR Incentive Programs Milestone
                  Timeline. (2010). [online] . Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/EHRIncentProgtimeline508V1.pdf"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/EHRIncentProgtimeline508V1.pdf
                  </a>
                </li>
                <li>
                  www.cms.gov. (n.d.). Medicare and Medicaid Promoting
                  Interoperability Program Basics | CMS. [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Basics#:~:text=In%202021%20all%20EPs%20will
"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Basics#:~:text=In%202021%20all%20EPs%20will
                  </a>
                </li>
                <li>
                  Anon, (n.d.). CMS Promoting Interoperability in 2021: All good
                  things must come to an end? – HLN Consulting, LLC. [online]
                  Available at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.hln.com/cms-promoting-interoperability-in-2021-all-good-things-must-come-to-an-end/index.html#:~:text=Promoting%20Interoperability%3A%20Medicaid%20%E2%80%93%20This%20program.
"
                  >
                    https://www.hln.com/cms-promoting-interoperability-in-2021-all-good-things-must-come-to-an-end/index.html#:~:text=Promoting%20Interoperability%3A%20Medicaid%20%E2%80%93%20This%20program.
                  </a>
                </li>
                <li>
                  www.cms.gov. (n.d.). Fiscal Year (FY) 2021 Medicare Hospital
                  Inpatient Prospective Payment System (IPPS) and Long Term
                  Acute Care Hospital (LTCH) Final Rule (CMS-1735-F) | CMS.
                  [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/newsroom/fact-sheets/fiscal-year-fy-2021-medicare-hospital-inpatient-prospective-payment-system-ipps-and-long-term-acute-0
"
                  >
                    https://www.cms.gov/newsroom/fact-sheets/fiscal-year-fy-2021-medicare-hospital-inpatient-prospective-payment-system-ipps-and-long-term-acute-0
                  </a>
                </li>
                <li>
                  Centers for Medicare and Medicaid Services (CMS) (2013).
                  Medicaid Electronic Health Record Incentive Payments for
                  Eligible Professionals. [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/MLN_MedicaidEHRProgram_TipSheet_EP.pdf
"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/MLN_MedicaidEHRProgram_TipSheet_EP.pdf
                  </a>
                </li>
                <li>
                  Centers for Medicare and Medicaid Services (CMS) (2013).
                  Medicaid Electronic Health Record Incentive Payments for
                  Eligible Professionals. [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/MLN_MedicaidEHRProgram_TipSheet_EP.pdf
"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/MLN_MedicaidEHRProgram_TipSheet_EP.pdf
                  </a>
                </li>
                <li>
                  &zwnj;Medicare Electronic Health Record Incentive Payments for
                  Eligible Professionals Participation in Other CMS Incentive
                  Programs. (n.d.). [online] . Available at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/MLN_MedicareEHRProgram_TipSheet_EP.pdf
"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/Downloads/MLN_MedicareEHRProgram_TipSheet_EP.pdf
                  </a>
                </li>
                <li>
                  &zwnj;www.cms.gov. (n.d.). 2020/2021 Program Requirements
                  Medicaid | CMS. [online] Available at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/regulations-guidance/promoting-interoperability/20202021-program-requirements-medicaid
"
                  >
                    https://www.cms.gov/regulations-guidance/promoting-interoperability/20202021-program-requirements-medicaid
                  </a>
                </li>
                <li>
                  www.cms.gov. (n.d.). 2020 Program Requirements Medicare | CMS.
                  [online] Available at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/2020ProgramRequirementsMedicare
"
                  >
                    https://www.cms.gov/Regulations-and-Guidance/Legislation/EHRIncentivePrograms/2020ProgramRequirementsMedicare
                  </a>
                </li>
                <li>
                  ecqi.healthit.gov. (n.d.). Eligible Professional / Eligible
                  Clinician eCQMs | eCQI Resource Center. [online] Available at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://ecqi.healthit.gov/ep-ec?year=2020
"
                  >
                    {' '}
                    https://ecqi.healthit.gov/ep-ec?year=2020
                  </a>
                </li>
                <li>
                  Anon, (n.d.). CMS Promoting Interoperability in 2021: All good
                  things must come to an end? – HLN Consulting, LLC. [online]
                  Available at:
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.hln.com/cms-promoting-interoperability-in-2021-all-good-things-must-come-to-an-end/index.html#:~:text=Promoting%20Interoperability%3A%20Medicaid%20%E2%80%93%20This%20program.
"
                  >
                    https://www.hln.com/cms-promoting-interoperability-in-2021-all-good-things-must-come-to-an-end/index.html#:~:text=Promoting%20Interoperability%3A%20Medicaid%20%E2%80%93%20This%20program.
                  </a>
                </li>
                <li>
                  qpp.cms.gov. (n.d.). About Exception Applications - QPP.
                  [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qpp.cms.gov/mips/exception-applications
"
                  >
                    https://qpp.cms.gov/mips/exception-applications
                  </a>
                </li>
                <li>
                  qpp.cms.gov. (n.d.). 2020 Quality Measures. [online] Available
                  at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qpp.cms.gov/mips/explore-measures/"
                  >
                    https://qpp.cms.gov/mips/explore-measures/
                  </a>
                </li>
                <li>
                  Bonamici, S. (2016). H.R.34 - 114th Congress (2015-2016): 21st
                  Century Cures Act. [online] www.congress.gov. Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.congress.gov/bill/114th-congress/house-bill/34"
                  >
                    https://www.congress.gov/bill/114th-congress/house-bill/34
                  </a>
                </li>
                <li>
                  Federal Register. (2020). 21st Century Cures Act:
                  Interoperability, Information Blocking, and the ONC Health IT
                  Certification Program. [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.federalregister.gov/documents/2020/05/01/2020-07419/21st-century-cures-act-interoperability-information-blocking-and-the-onc-health-it-certification."
                  >
                    https://www.federalregister.gov/documents/2020/05/01/2020-07419/21st-century-cures-act-interoperability-information-blocking-and-the-onc-health-it-certification.
                  </a>
                </li>
                <li>
                  Mullin, R. (2017). MACRA, and 21st Century Cures Act Will
                  Drive Interoperability Forward. [online] Health IT Answers.
                  Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.healthitanswers.net/macra-21st-century-cures-act-will-drive-interoperability-forward/"
                  >
                    https://www.healthitanswers.net/macra-21st-century-cures-act-will-drive-interoperability-forward/
                  </a>
                </li>
                <li>
                  Amazonaws.com. (2017). [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qpp-cm-prod-content.s3.amazonaws.com/uploads/179/2018%20MIPS%20Scoring%20Guide_Final.pdf"
                  >
                    https://qpp-cm-prod-content.s3.amazonaws.com/uploads/179/2018%20MIPS%20Scoring%20Guide_Final.pdf
                  </a>
                </li>
                <li>
                  Amazonaws.com. (2021). [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qpp-cm-prod-content.s3.amazonaws.com/uploads/0/2019%20MIPS%20Scoring%20Guide.pdf"
                  >
                    https://qpp-cm-prod-content.s3.amazonaws.com/uploads/0/2019%20MIPS%20Scoring%20Guide.pdf
                  </a>
                </li>
                <li>
                  Amazonaws.com. (2018). [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://qpp-cm-prod-content.s3.amazonaws.com/uploads/819/2020%20MIPS%20Quick%20Start%20Guide.pdf"
                  >
                    https://qpp-cm-prod-content.s3.amazonaws.com/uploads/819/2020%20MIPS%20Quick%20Start%20Guide.pdf
                  </a>
                </li>
                <li>
                  Able Health. (2020). Complete Guide to MIPS 2021: scoring,
                  payment adjustments, measures, and reporting frameworks.
                  [online] Available at:{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://ablehealth.com/2020/08/06/complete-guide-to-mips-2021/
"
                  >
                    https://ablehealth.com/2020/08/06/complete-guide-to-mips-2021/
                  </a>
                </li>
              </ol>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default PatientPortalMacra;
